// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/forms/SideForm.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/forms/SideForm.tsx");
  import.meta.hot.lastModified = "1734531575331.0566";
}
// REMIX HMR END

import { useNavigate } from "@remix-run/react";
import { useContext, useState } from "react";
import { AppContext } from "@/root";
import Search from "@/routes/search";
function SideForm() {
  _s();
  const navigate = useNavigate();
  const [isSearchError, setIsSearchError] = useState(false);
  const {
    company
  } = useContext(AppContext);
  const handle = () => {
    if (company) {
      navigate(`/formdata`, {
        state: {
          fromMainSearch: true
        }
      });
    } else {
      setIsSearchError(true);
    }
  };
  return <div className="row-span-3 w-full float-right">
      <div className="max-w-[590px] transparent items-center m-auto ">
        <div className="mx-auto px-9 py-1 pb-6 bg-sky-200 rounded-lg border border-slate-200">
          <div className="text-sm font-medium mt-6 flex items-center">
            <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.648438 6.01116C0.648438 7.11416 1.59344 8.01616 2.74844 8.01616H3.83344M15.6484 7.03916H15.6584M18.6514 4.15316C19.0264 4.51816 19.3754 4.90816 19.4994 5.51316C19.6434 6.20716 20.5844 6.01116 21.1234 6.01116C21.4134 6.01116 21.6484 6.23516 21.6484 6.51216V9.52116C21.6484 10.6512 17.4484 10.7052 17.4484 12.3382C17.4484 13.2752 18.0244 16.0392 16.3984 16.0392H14.9474C14.0294 16.0392 13.7284 14.9502 13.3934 14.3112C13.0944 13.7402 10.2524 13.7402 9.95344 14.3112C9.61844 14.9512 9.31744 16.0392 8.39844 16.0392H6.94844C5.40444 16.0392 5.89844 13.6452 5.89844 12.7372C5.89602 12.6015 5.84035 12.4722 5.74344 12.3772C0.389437 7.00016 7.20344 -0.597842 14.7884 2.73716C15.0844 2.86716 15.4624 2.73816 15.6604 2.49216C16.1144 1.93016 16.9764 1.23716 17.9764 1.04716C18.2614 0.993158 18.4984 1.22116 18.4984 1.49816C18.4984 2.12416 18.1474 3.66216 18.6514 4.15316Z" stroke="#232323" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <p className="ml-2">
              Søk hos over <span className="font-bold">30 banker</span> og velg
              det beste tilbudet.
            </p>
          </div>
          <div className="mt-6">
            {isSearchError && <p className="text-red-500">Velg et selskap fra listen</p>}

            <Search />
            <button className="flex items-center h-[60px] justify-center bg-emerald-700 text-white font-bold py-2 px-4 mt-6 rounded-full hover:bg-emerald-600 w-full" onClick={handle}>
              Gå videre
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
              marginLeft: "8px"
            }}>
                <path d="M4 12H20M20 12L16 8M20 12L16 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>

          <div className="flex mt-6 flex-col sm:flex-row">
            <ul className="my-4 sm:w-1/2">
              <li className="text-black flex font-bold">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2">Gratis</span>
              </li>
              <li className="text-black flex font-bold">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2">Uforpliktende</span>
              </li>
              <li className="text-black flex">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2">Søk raskt og enkelt</span>
              </li>
            </ul>

            <ul className="sm:ml-4 sm:mt-4 sm:w-1/2">
              <li className="text-black flex ">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2">Raskt svar</span>
              </li>
              <li className="text-black flex">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2">Gode betingelser</span>
              </li>
              <li className="text-black flex">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.6665 12.304L8.57186 17.2041L8.96379 16.5188C11.0652 12.8444 13.9733 9.69492 17.469 7.3079L18.3332 6.71777" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="ml-2">Ingen skjulte kostnadder</span>
              </li>
            </ul>
          </div>

          <div className="w-full mt-2 h-0.5 rounded-lg bg-white"></div>

          <p className="text-sm mt-6 gary-primary flex items-center">
            <span className="mr-2">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 17.4609V15.4609M12 15.4609C12.513 15.4609 12.9288 15.0132 12.9288 14.4609C12.9288 13.9087 12.513 13.4609 12 13.4609C11.487 13.4609 11.0712 13.9087 11.0712 14.4609C11.0712 15.0132 11.487 15.4609 12 15.4609ZM17.573 9.69958V9.46094C17.573 6.14723 15.0779 3.46094 12 3.46094C8.92209 3.46094 6.42696 6.14723 6.42696 9.46094V9.69958M17.573 9.69958C16.8871 9.46094 15.9654 9.46094 14.3028 9.46094H9.69722C8.03464 9.46094 7.11294 9.46094 6.42696 9.69958M17.573 9.69958C17.6567 9.7287 17.7369 9.76137 17.8146 9.79803C18.766 10.2475 19.4992 11.1053 19.8362 12.1635C20.089 12.9571 20.0115 13.9578 19.8566 15.9592C19.7232 17.6818 19.6566 18.543 19.3494 19.219C18.9398 20.1202 18.2305 20.8229 17.3623 21.1875C16.7111 21.4609 15.9083 21.4609 14.3028 21.4609H9.69722C8.09169 21.4609 7.28893 21.4609 6.63775 21.1875C5.7695 20.8229 5.06024 20.1202 4.65063 19.219C4.34342 18.543 4.27676 17.6818 4.14343 15.9592C3.98851 13.9578 3.91105 12.9571 4.16381 12.1635C4.50083 11.1053 5.234 10.2475 6.18545 9.79803C6.26305 9.76137 6.34325 9.7287 6.42696 9.69958" stroke="#2D373A" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
            Personvernet ditt ivaretas: <br /> Vi behandler alle data etter
            personvernforordningen (GDPR).
          </p>
        </div>
      </div>
    </div>;
}
_s(SideForm, "ti8/VO4VWEqpui8kcQVhZdqBQg0=", false, function () {
  return [useNavigate];
});
_c = SideForm;
export default SideForm;
var _c;
$RefreshReg$(_c, "SideForm");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;